<template>
	<BaseCardWH :header="blockLabel" padding="0 26px 30px" header_margin="0 -26px 10px" v-if="this.activeBlock && Object.keys( this.activeBlock ).length > 0">
		<Button variant="btn-danger" class="product-create__cancel w-100 block-settings__remove" :action="deleteBlock" v-if="!disabled && !prefilledMode">
			Delete block
		</Button>
			<div
				class="block-settings__list"
				v-for="( obj, idx ) in settingsObject"
				v-bind:key="idx"
				:set="key = Object.keys(obj)[0]"
			>  
			<CollapsibleContentLight  v-bind:title="optionTitle(activeBlock.fields[key])" v-if="settingsObject.length && (!schema || (schema && !schema[key]) || (schema && schema[key] && schema[key].editable))" class="block-settings-collapsible" :collapseId="`zsu-${idx}`" :collapseRndId="`zsu-${idx}`">
					<template v-if="activeBlock.fields[key].datatype === 'color'">
						<FormulateInput
							type="color"
							:name="key"
							:disabled="disabled"
							v-bind:value="obj[key]"
							v-bind:label="activeBlock.fields[key].label"
							v-on:input="updateFieldData( Object.keys(obj)[0], $event )"
							v-bind:key="settingsKey"
							:id="settingsKey + '-' + Object.keys(obj)[0]"
						/>
						<button class="btn btn-danger" @click="resetColor(Object.keys(obj)[0], settingsKey, idx)">
							Reset
						</button>
					</template>

					<template v-if="activeBlock.fields[key].datatype === 'text'">
						<FormulateInput
							type="text"
							:name="key"
							:disabled="disabled"
							v-bind:value="obj[key]"
							v-bind:class="editableClass"
							v-bind:label="activeBlock.fields[key].label"
							v-on:input="updateFieldData( Object.keys(obj)[0], $event )"
							v-bind:key="settingsKey"
						/>
					</template>

					<template v-if="activeBlock.fields[key].datatype === 'number'">
						<FormulateInput
							type="number"
							:name="key"
							:disabled="disabled"
							v-bind:value="obj[key]"
							v-bind:class="editableClass"
							v-bind:label="activeBlock.fields[key].label"
							v-on:input="updateFieldData( Object.keys(obj)[0], $event )"
							v-bind:key="settingsKey"
						/>
					</template>
					

					<template v-if="activeBlock.fields[key].datatype === 'image'">
						<ImageInput
							:label="activeBlock.fields[key].label"
							:value="obj[key]"
							:url="fileUrl(obj[key])"
							:name="key"
							:editable="!disabled"
							v-on:image-changed="updateFieldData(Object.keys(obj)[0], $event)"
							v-bind:key="settingsKey" />
					</template>

					<template v-if="activeBlock.fields[key].datatype === 'file'">
						<FileInput
							:label="activeBlock.fields[key].label"
							:value="obj[key]"
							:editable="!disabled"
							:url="fileUrl(obj[key])"
							:name="key"
							v-on:file-changed="updateFieldData(Object.keys(obj)[0], $event)"
							v-bind:key="settingsKey" />
					</template>

					<template v-if="activeBlock.fields[key].datatype === 'checkbox'">
						<FormulateInput
							type="checkbox"
							:name="key"
							:disabled="disabled"
							v-bind:value="obj[key]"
							v-bind:checked="obj[key]"
							v-bind:label="activeBlock.fields[key].label"
							v-on:input="updateFieldData( Object.keys(obj)[0], $event )"
							v-bind:key="settingsKey"
						/>
					</template>

					<template v-if="activeBlock.fields[key].datatype === 'date'">
						<FormulateInput
							type="date"
							:name="key"
							:disabled="disabled"
							v-bind:value="obj[key]"
							v-bind:checked="obj[key]"
							v-bind:class="editableClass"
							v-bind:label="activeBlock.fields[key].label"
							v-on:input="updateFieldData( Object.keys(obj)[0], $event )"
							v-bind:key="settingsKey"
						/>
					</template>

					<template v-if="activeBlock.fields[key].datatype === 'list'">
						<List 
							v-bind:value="obj[key]"
							:editable="!disabled"
							v-bind:label="activeBlock.fields[key].label"
							v-bind:object="activeBlock.fields[key].object"
							v-on:list-changed="updateFieldData( Object.keys(obj)[0], $event )"
							v-bind:key="settingsKey"
							v-bind:is-settings="true"
						/>
					</template>

					<template v-if="activeBlock.fields[key].datatype === 'select'">
						<FormulateInput
							type="select"
							:name="key"
							:disabled="disabled"
							:key="settingsKey"
							v-bind:class="editableClass"
							:options="activeBlock.fields[key].options"
							v-bind:value="obj[key]"
							v-bind:label="activeBlock.fields[key].label"
							v-on:input="updateFieldData( Object.keys(obj)[0], $event )"
						/>
					</template>
				</CollapsibleContentLight >
			</div>
	</BaseCardWH>
</template>

<script>
	import { mapMutations } from 'vuex';
	import { debounce } from '@/services/debounce'
	import Button from "@/components/Buttons/Button";

	export default {
		name: "BlockSettings",
		components: {
			BaseCardWH: () => import( '@/components/Cards/BaseCardWH' ),
			CollapsibleContentLight : () => import( '@/components/Collapsible/CollapsibleContentLight' ),
			ImageInput: () => import( '@/components/Input/ImageInput.vue' ),
			FileInput: () => import( '@/components/Input/FileInput.vue' ),
			List: ()  => import( '@/components/List/List' ),
			Button
		},
		data() {
			return {
				id: '3',
				checkboxValue: false,
				selectedFile: "",
				value: '',
			}
		},
		props: {
			header: {
				type: String,
			},
			dataId:{
				type: String
			},
			dataObject: {
				type: Object,
			},
			activeBlock: {
				type: Object,
			},
			settingsKey: {
				type: Number,
			},
			disabled:{
				type: Boolean,
				default: false
			},
			prefilledMode:{
				type:Boolean
			},
			schema:{
				type:Object
			}
		},
		computed: {
			blockLabel() {
				let output = '';
				if ( this.activeBlock ) {
					output = this.activeBlock.name;
				}
				return output;
			},
			settingsObject() {
				let output = [];
				if ( this.activeBlock ) {
					for ( let prop in this.activeBlock.fields ) {
						if ( this.activeBlock.fields[prop].location === 'sidebar' ) {
							if ( this.dataObject && this.dataObject[prop] ) {
								output.push( { [prop]: this.dataObject[prop] } );
							} else {
								output.push( { [prop]: this.activeBlock.fields[prop].default ? this.activeBlock.fields[prop].default : '' } );

								if( this.activeBlock.fields[prop].default ){
									// we need to update state if we use default value provided by block
									this.updateFieldData( prop, this.activeBlock.fields[prop].default );
									this.$emit( 'set-default-settings', { 
										key: prop,
										value: this.activeBlock.fields[prop].default
									} );
								}
							}
						}
					}
				}
				return output;
			},
			editableClass(){
				if( this.disabled )
				{
					return "not-editable";
				}
				return "";
			},
		},
		methods: {
			...mapMutations( 'blockEditor', {
				deleteBlockFromStructure: 'deleteBlockFromStructure',
				updateActiveBlock: 'updateActiveBlock',
				updateData: 'updateData'
			} ),
			fileUrl( file ){
				if( typeof file === 'object' && file.name ){
					return URL.createObjectURL( new Blob(
						[ file ], 
						{ type: file.type }
					) );
				}

				if( typeof file === 'string' ){
					if (file === "") return;
					return window.location.origin + file;
				}

				return '';
			},
			updateFieldData( key,val ){
				debounce( () => {
					this.updateData( { dataId: this.dataId, value: val, key: key } );
				}, 500, this.dataId + key );
			},
			resetColor( key, settingsKey, index ){
				// Reset color picker to it's default color
				const colorInput = document.getElementById(settingsKey + '-' + key);
				colorInput.value = '';
				colorInput.dispatchEvent(new Event('input'));

				// Remove color from block settings
				debounce( () => {
					this.settingsObject[index][key] = '';
					this.updateData( { dataId: this.dataId, value: '', key: key } );
				}, 500, this.dataId + key );
			},
			deleteBlock() {
				this.deleteBlockFromStructure( this.dataId );
				this.updateActiveBlock( {} );
			},
			optionTitle( option ){
				if( option.settingsLabel ) return option.settingsLabel;
				
				return option.label;
			}
		},
	}
</script>

<style lang="scss" scoped>
	@import '@/styles/variables';

	::v-deep .not-editable input,
	::v-deep .not-editable .editor,
	::v-deep .not-editable .editor__content,
	::v-deep .not-editable select {
		border:none!important;
		padding:0px;
	}	

	::v-deep .vue-gpickr[data-v-1eec65a6]{
		display: flex;
		flex-direction: column;
	}

    ::v-deep .custom-button{
		box-shadow: none;
	}
	::v-deep .collapsible-content {
        &_header {
            font-family: inherit;
            color: $dark_text;
            font-size: 0.825rem;

            &.not-collapsed {
				color: $dark_text;
			}
        }
		.btn.collapsible-content_header {
			font-family: 'Lato';
			font-weight: bold;
			font-size: 0.975rem;
			line-height: 200%;
			color: $dark;
			text-transform: capitalize;
			border-top: 1px solid $light_gray!important;

			&.not-collapsed {
				color: $focus_color;
			}
		}

		.collapse {

			.collapse{
				padding-left: 0px;
				padding-right: 0px;
			}
		}

		&:last-child {

			.btn.collapsible-content_header {
				border-bottom: unset;
			}
		}

		.collapsible-content-body {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}

	.block-settings__list {
        border-bottom: 1px solid $light_gray;
        &:last-child {
            border-bottom: none;
        }
		&:first-of-type {
			::v-deep .collapsible-content {
				.btn.collapsible-content_header {
					border-top: 0!important;
				}
			}
		}
		&:last-of-type {
			::v-deep .collapsible-content {
				.btn.collapsible-content_header {
					padding-bottom: 0;

					&.not-collapsed {
						padding-bottom: 15px;
					}
				}
			}
		}
	}

	.formulate-input {
		::v-deep {
			.formulate-input-element--color[data-type="color"] {
				display: flex;

				input[type=color] {
					width: 100%;
				}
			}
		}
	}

	.block-settings-collapsible ::v-deep .list-item{
		width: 100% !important;
		padding: 15px;
		margin-top:15px;
		margin-bottom: 0px;
	}
	.block-settings-collapsible ::v-deep label + .list-item{
		margin-top:0px;
	}
	.block-settings__remove {
		margin-top: 20px;
	}
	::v-deep {
		.formulate-input[data-classification="box"] {
			label {
				margin-bottom: 0;
				margin-top: 0;
                font-size: 1rem;
                font-weight: bold;
                font-family: 'Lato';
			}
			.formulate-input-label {
                margin-left: 10px;
				margin-bottom: 2px;
            }
		}
	}

</style>
